import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a63cd0e4 = () => interopDefault(import('../client/pages/kits/index.vue' /* webpackChunkName: "pages/kits/index" */))
const _76165804 = () => interopDefault(import('../client/pages/lojas/index.vue' /* webpackChunkName: "pages/lojas/index" */))
const _02cbdf9c = () => interopDefault(import('../client/pages/mapa-do-site/index.vue' /* webpackChunkName: "pages/mapa-do-site/index" */))
const _fbcd409c = () => interopDefault(import('../client/pages/minha-conta/index.vue' /* webpackChunkName: "pages/minha-conta/index" */))
const _48b4eb0a = () => interopDefault(import('../client/pages/motos/index.vue' /* webpackChunkName: "pages/motos/index" */))
const _23b5f414 = () => interopDefault(import('../client/pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _6b31c496 = () => interopDefault(import('../client/pages/veiculos/index.vue' /* webpackChunkName: "pages/veiculos/index" */))
const _e430c310 = () => interopDefault(import('../client/pages/checkout/carrinho/index.vue' /* webpackChunkName: "pages/checkout/carrinho/index" */))
const _4c32e1f8 = () => interopDefault(import('../client/pages/checkout/confirmacao/index.vue' /* webpackChunkName: "pages/checkout/confirmacao/index" */))
const _1b81424e = () => interopDefault(import('../client/pages/checkout/pagamento/index.vue' /* webpackChunkName: "pages/checkout/pagamento/index" */))
const _061af0a6 = () => interopDefault(import('../client/pages/minha-conta/enderecos/index.vue' /* webpackChunkName: "pages/minha-conta/enderecos/index" */))
const _43692db2 = () => interopDefault(import('../client/pages/minha-conta/meus-dados/index.vue' /* webpackChunkName: "pages/minha-conta/meus-dados/index" */))
const _8878769e = () => interopDefault(import('../client/pages/minha-conta/pedidos/index.vue' /* webpackChunkName: "pages/minha-conta/pedidos/index" */))
const _69f9302a = () => interopDefault(import('../client/pages/usuario/cadastro/index.vue' /* webpackChunkName: "pages/usuario/cadastro/index" */))
const _40e41266 = () => interopDefault(import('../client/pages/usuario/login/index.vue' /* webpackChunkName: "pages/usuario/login/index" */))
const _86367076 = () => interopDefault(import('../client/pages/checkout/confirmacao/pix.vue' /* webpackChunkName: "pages/checkout/confirmacao/pix" */))
const _ddfa1994 = () => interopDefault(import('../client/pages/produto/_id/_.vue' /* webpackChunkName: "pages/produto/_id/_" */))
const _444e505a = () => interopDefault(import('../client/pages/kit/_id/_.vue' /* webpackChunkName: "pages/kit/_id/_" */))
const _5fce7155 = () => interopDefault(import('../client/pages/lista/_.vue' /* webpackChunkName: "pages/lista/_" */))
const _07a80920 = () => interopDefault(import('../client/pages/informacoes-gerais/_.vue' /* webpackChunkName: "pages/informacoes-gerais/_" */))
const _902e7f58 = () => interopDefault(import('../client/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kits",
    component: _a63cd0e4,
    name: "kits"
  }, {
    path: "/lojas",
    component: _76165804,
    name: "lojas"
  }, {
    path: "/mapa-do-site",
    component: _02cbdf9c,
    name: "mapa-do-site"
  }, {
    path: "/minha-conta",
    component: _fbcd409c,
    name: "minha-conta"
  }, {
    path: "/motos",
    component: _48b4eb0a,
    name: "motos"
  }, {
    path: "/reset",
    component: _23b5f414,
    name: "reset"
  }, {
    path: "/veiculos",
    component: _6b31c496,
    name: "veiculos"
  }, {
    path: "/checkout/carrinho",
    component: _e430c310,
    name: "checkout-carrinho"
  }, {
    path: "/checkout/confirmacao",
    component: _4c32e1f8,
    name: "checkout-confirmacao"
  }, {
    path: "/checkout/pagamento",
    component: _1b81424e,
    name: "checkout-pagamento"
  }, {
    path: "/minha-conta/enderecos",
    component: _061af0a6,
    name: "minha-conta-enderecos"
  }, {
    path: "/minha-conta/meus-dados",
    component: _43692db2,
    name: "minha-conta-meus-dados"
  }, {
    path: "/minha-conta/pedidos",
    component: _8878769e,
    name: "minha-conta-pedidos"
  }, {
    path: "/usuario/cadastro",
    component: _69f9302a,
    name: "usuario-cadastro"
  }, {
    path: "/usuario/login",
    component: _40e41266,
    name: "usuario-login"
  }, {
    path: "/checkout/confirmacao/pix",
    component: _86367076,
    name: "checkout-confirmacao-pix"
  }, {
    path: "/produto/:id?/*",
    component: _ddfa1994,
    name: "produto-id-all"
  }, {
    path: "/kit/:id?/*",
    component: _444e505a,
    name: "kit-id-all"
  }, {
    path: "/lista/*",
    component: _5fce7155,
    name: "lista-all"
  }, {
    path: "/informacoes-gerais/*",
    component: _07a80920,
    name: "informacoes-gerais-all"
  }, {
    path: "/",
    component: _902e7f58,
    name: "index"
  }],

  parseQuery: function(query) {
			const qs = require("qs")

			return qs.parse(query)
		},
  stringifyQuery: function(query) {
			const qs = require("qs")

			const result = qs.stringify(query)

			return result ? `?${result}` : ""
		},
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
